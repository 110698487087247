<template>
  <div class="financial-resume-container">
    <CardHeader
      title="Resumo do faturamento"
      periodType="month"
      periodFormat="MMMM [de] YYYY"
      optionPlaceholder="Todas as operadoras"
      :options="tissHealthPlansOptions"
      @changeOption="changeOption"
      @changePeriod="changePeriod"
    />
    <div class="divider" />
    <div class="values-container" v-if="loading">
      <div class="value-wrapper" v-for="item in new Array(5)" :key="item">
        <b-skeleton animation="wave" width="250px" />
        <b-skeleton animation="wave" width="55%" />
        <b-skeleton animation="wave" width="70%" />
      </div>
    </div>
    <div class="values-container" v-else>
      <div class="value-wrapper">
        <div class="description">Valor faturado</div>
        <div class="value faturado">
          <div class="prefix">R$</div>
          {{ invoicedAmount }}
        </div>
      </div>
      <div class="pipe" />
      <div class="value-wrapper">
        <div class="description">Valor pago pela operadora</div>
        <div class="value pago">
          <div class="prefix">R$</div>
          {{ amountPaid }}
          <span id="paidDiff" :class="{ 'negative' : diffAmountPaid < 0 }">~{{ diffAmountPaid }}%</span>
          <b-tooltip target="paidDiff" triggers="hover"> Pagos aproximadamente {{ diffAmountPaid }}% do valor faturado </b-tooltip>
        </div>
      </div>
      <div class="pipe" />
      <div class="value-wrapper">
        <div class="description">Valor glosado</div>
        <div class="value glosado">
          <div class="prefix">R$</div>
          {{ glossedValue }}
          <span id="glossedDiff" :class="{ 'negative' : diffGlossedValue < 0 }">~{{ diffGlossedValue }}%</span>
          <b-tooltip target="glossedDiff" triggers="hover"> Glosados aproximadamente {{ diffGlossedValue }}% do valor faturado </b-tooltip>
        </div>
      </div>
      <div v-if="false">
        <div class="pipe" />
        <div class="value-wrapper">
          <div class="description">Valor recuperado</div>
          <div class="value recuperado">
            <div class="prefix">R$</div>
            {{ recoveredValue }}
            <span id="recoveredDiff" :class="{ 'negative' : diffRecoveredValue < 0 }">~{{ diffRecoveredValue }}%</span>
            <b-tooltip target="recoveredDiff" triggers="hover"> Recuperados aproximadamente {{ diffRecoveredValue }}% do valor faturado </b-tooltip>
          </div>
        </div>
        <div class="pipe" />
        <div class="value-wrapper">
          <div class="description">Valor total recebido</div>
          <div class="value recebido">
            <div class="prefix">R$</div>
            {{ receivedValue }}
            <span id="receivedDiff" :class="{ 'negative' : diffReceivedValue < 0 }">~{{ diffReceivedValue }}%</span>
            <b-tooltip target="receivedDiff" triggers="hover"> Recebidos aproximadamente {{ diffReceivedValue }}% do valor faturado </b-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'
import CardHeader from '@/layouts/InvoicingTiss/Dashboard/CardHeader'

export default {
  components: { CardHeader },
  props: {
    tissHealthPlansOptions: Array
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      period: this.moment().startOf('month').toDate(),
      clinicHealthPlan: null,
      invoicedAmount: 0,
      amountPaid: 0,
      glossedValue: 0,
      recoveredValue: 0,
      totalAmountReceived: 0,
      receivedValue: 0,
      diffAmountPaid: 0,
      diffGlossedValue: 0,
      diffRecoveredValue: 0,
      diffReceivedValue: 0,
      loading: false
    }
  },
  async created() {
    await this.getResume()
  },
  methods: {
    parseNumberToMoney,
    changePeriod(period) {
      this.period = period
    },
    changeOption(option) {
      this.clinicHealthPlan = option.value
    },
    async getResume() {
      const data = {
        date: this.period,
        clinicHealthPlanId: this.clinicHealthPlan
      }
      const dataApi = await this.billingSummary(this.clinic.id, data)
      this.invoicedAmount = this.parseNumberToMoney(dataApi.invoicedAmount).replace('R$', '')
      this.amountPaid = this.parseNumberToMoney(dataApi.amountPaid).replace('R$', '')
      this.glossedValue = this.parseNumberToMoney(dataApi.glossedValue).replace('R$', '')
      this.recoveredValue = this.parseNumberToMoney(dataApi.recoveredValue).replace('R$', '')
      this.totalAmountReceived = this.parseNumberToMoney(dataApi.totalAmountReceived).replace('R$', '')
      this.receivedValue = this.parseNumberToMoney(dataApi.receivedValue).replace('R$', '')

      this.diffAmountPaid = dataApi.diffs.amountPaid
      this.diffGlossedValue = dataApi.diffs.glossedValue
      this.diffRecoveredValue = dataApi.diffs.recoveredValue
      this.diffReceivedValue = dataApi.diffs.receivedValue
    },
    async billingSummary(clinicId, item) {
      this.loading = true
      try {
        const { data } = await this.api.billingSummary(clinicId, item)
        return data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    async period() {
      await this.getResume()
    },
    async clinicHealthPlan() {
      await this.getResume()
    }
  }
}
</script>
<style lang="scss" scoped>
.financial-resume-container {
  border: 1px solid var(--neutral-200);
  border-radius: 8px;
  padding: 24px;
}

.divider {
  width: 100%;
  height: 1px;
  margin: 10px 0 15px 0;
  background-color: var(--neutral-200);
}

.values-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.value-wrapper {
  .description {
    font-weight: 700;
    font-size: min(1vw, 14px);
  }

  .value {
    font-weight: 700;
    font-size: min(1.3vw, 18px);
    display: flex;
    align-items: center;
    gap: 5px;

    .prefix {
      font-size: min(1vw, 14px);
    }

    span {
      font-weight: 700;
      font-size: 12px;
      padding: 4px 8px;
      border-radius: 16px;
    }

    &.faturado {
      color: var(--dark-blue);
    }

    &.pago {
      color: var(--states-success-dark-green);

      span {
        background-color: var(--states-success-soft-green);
      }
    }

    &.glosado {
      color: var(--orange);

      span {
        background-color: var(--light-orange-200);
      }
    }

    &.recuperado {
      color: var(--neutral-700);

      span {
        background-color: var(--blue-100);
      }
    }

    &.recebido {
      color: var(--blue-500);

      span {
        background-color: var(--blue-100);
      }
    }
  }

  .negative {
    background-color: var(--states-red-soft) !important;
    color: var(--neutral-700) !important;
  }
}

.pipe {
  width: 1px;
  height: 50px;
  margin: 0 10px;
  background-color: var(--neutral-200);
}
</style>
