<template>
    <div>
      <section class="header">
        <div class="title">
          <div>Dashboard</div>
          <span id="help-icon" class="icon-box">
            <HelpCircle class="icon stroke" />
          </span>
          <b-tooltip target="help-icon" placement="bottom">
            Visualizar relatórios do faturamento
          </b-tooltip>
        </div>
        <!-- <div class="btn-title-container">
          <b-button variant="outline-primary no-print" @click="print">Imprimir</b-button>
        </div> -->
      </section>
      <section>
        <FinancialResume :tissHealthPlansOptions="tissHealthPlansOptions" />
      </section>
      <br/>
      <section>
        <FinancialReport :tissHealthPlansOptions="tissHealthPlansOptions" />
      </section>
      <br/>
      <section>
        <FinancialHealthPlans />
      </section>
    </div>
</template>
<script>
import HelpCircle from '@/assets/icons/help-circle.svg'
import FinancialResume from '@/layouts/InvoicingTiss/Dashboard/FinancialResume'
import FinancialReport from '@/layouts/InvoicingTiss/Dashboard/FinancialReport'
import FinancialHealthPlans from '@/layouts/InvoicingTiss/Dashboard/FinancialHealthPlans'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  components: {
    HelpCircle,
    FinancialResume,
    FinancialReport,
    FinancialHealthPlans,
  },
  props: {
    print: Function
  },
  data: () => ({
    clinic: getCurrentClinic(),
    tissHealthPlansOptions: []
  }),
  async mounted() {
    await this.getHealthPlans()
  },
  methods: {
    async getHealthPlans() {
      try {
        const { data } = await this.api.getActiveInvoicingTissClinicHealthPlans(
          this.clinic.id,
          1,
          null,
          null,
          true
        )
        this.tissHealthPlansOptions = data.map(healthPlan => ({
          label: healthPlan?.health_plan?.fantasy_name,
          value: healthPlan.id
        }))
        this.tissHealthPlansOptions.unshift({ label: 'Todas as operadoras', value: null })
      } catch (error) {
        this.$toast.error('Erro ao buscar planos de saúde')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 30px 0;

    .title {
      font-family: 'Nunito Sans';
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 0;
      color: var(--type-active);
      display: flex;
      align-items: center;

      .icon {
        height: 24px;
        width: 24px;
        margin-left: 5px;
        stroke: var(--neutral-500);
      }
    }
  }
</style>
