<template>
  <div class="financial-health-plan-container">
    <div class="header-container">
      <CardHeader
        title="Faturamento por convênio"
        periodType="year"
        periodFormat="[Ano de] YYYY"
        optionPlaceholder="Selecionar ordenação"
        :options="options"
        @changeOption="changeOption"
        @changePeriod="changePeriod"
      />
    </div>
    <b-table-simple striped class="table-financial-health-plans">
      <thead>
        <tr>
          <td :style="{ width: '100%', textAlign: 'left' }">Convênio</td>
          <td>Guias faturadas</td>
          <td>Qtde lotes</td>
          <td>Valor glosado</td>
          <td>Valor faturado</td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="el, index in data"
          :key="'financial-health-plan-' + el.health_plan + index"
        >
          <td :style="{ textAlign: 'left' }">{{ el.health_plan }}</td>
          <td>{{ el.invoiced_guides }}</td>
          <td>{{ el.batches }}</td>
          <td>{{ parseNumberToMoney(el.glossed_amount) }} ({{ el.invoiced_amount != 0 ? ((el.glossed_amount * 100) / el.invoiced_amount).toFixed(2) : '-' }}%)</td>
          <td>{{ parseNumberToMoney(el.invoiced_amount) }}</td>
        </tr>
      </tbody>
    </b-table-simple>

    <div class="pagination-position">
      <b-pagination
        v-model="page"
        :total-rows="count"
        :per-page="limit"
        first-number
        last-number
        size="sm"
      />
    </div>
  </div>
</template>
<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'
import CardHeader from '@/layouts/InvoicingSus/Dashboard/CardHeader'

export default {
  components: { CardHeader },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),

      period: this.moment().startOf('year').toDate(),
      option: 'alphabetic',
      data: null,
      page: 1,
      count: 0,
      limit: 0,
      options: [
        { label: 'Ordem alfabética', value: 'alphabetic' },
        { label: 'Maior quantidade de guias', value: 'more_guides' },
        { label: 'Maior valor glosado', value: 'highest_glossed_amount' },
        { label: 'Menor valor glosado', value: 'lowest_glossed_amount' },
        { label: 'Maior valor faturado', value: 'highest_invoiced_amount' },
        { label: 'Menor valor faturado', value: 'lowest_invoiced_amount' },
      ],
    }
  },
  mounted() {
    this.getFinancialByHealthPlans()
  },
  methods: {
    parseNumberToMoney,
    changePeriod(period) {
      this.period = period
    },
    changeOption(option) {
      this.option = option.value
    },
    async getFinancialByHealthPlans() {
      const data = {
        date: this.period,
        order: this.option
      }
      await this.api.financialByHealthPlans(this.clinic.id, data)
        .then(res => {
					this.data = res.data
				})
				.catch(err => {
					this.$toast.error(err.message)
				})
    }
  },
  watch: {
    period() { this.getFinancialByHealthPlans() },
    option() { this.getFinancialByHealthPlans() }
  }
}
</script>
<style lang="scss" scoped>
  .financial-health-plan-container {
    border: 1px solid var(--neutral-200);
    border-radius: 8px;
    margin-bottom: 30px;
  }
  .header-container {
    padding: 20px;
  }
  .table-financial-health-plans {
    tr, td {
      text-align: center;
      white-space: nowrap !important;
    }
    thead > tr > td {
      font-weight: 700;
      font-size: min(1.1vw, 14px);
      color: var(--neutral-700);
      background-color: var(--blue-100);
      border: none;
    }
    body > tr > td {
      font-weight: 600;
      font-size: min(1vw, 14px);
      color: var(--type-active);
      border: none;
    }
  }
</style>
